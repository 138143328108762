<template>
  <v-dialog v-model="dialog" max-width="700" persistent>
    <v-card raised class="mx-auto">
      <v-toolbar dense flat color="grey lighten-3">
        <v-toolbar-title class="subtitle-1"><span>Serial Search</span>
          <span class="ml-2" v-if="product">
            <v-chip small class="text-capitalize" :color="product.status === 'sold' ? 'red' : 'teal'"
              text-color="white">
              {{ product.status }}
            </v-chip>
          </span>
        </v-toolbar-title>
        <v-spacer> </v-spacer>
        <v-btn small icon @click.stop.prevent="close">
          <v-icon small>close</v-icon>
        </v-btn>
      </v-toolbar>
      <!-- <ModelTitle title="Serial Search" @close="close" /> -->

      <v-card-text>
        <v-row class="mt-5" justify="space-between">
          <v-col>
            <v-text-field @focus="$event.target.select()" label="Serial Number" hide-details outlined
              prepend-inner-icon="search" clearable dense rounded @keyup.enter="loadImeiNumber()"
              v-model.trim="returnImeiNumber" ref="returnImeiNumber">
            </v-text-field>
          </v-col>
          <v-col cols="auto">
            <v-btn color="success" :loading="textLoading" @click="loadImeiNumber()">Search</v-btn>
          </v-col>
        </v-row>
        <div v-if="product">
          <v-card-text class="subtitle-1">
            <v-row>
              <v-col>
                <span>Name: </span>
                <span class="font-weight-bold">{{ product.Product.name }}</span>
              </v-col>
              <v-col class="text-right" cols="3">
                <span>ID: </span>
                <span class="font-weight-bold">{{ product.Product.localId }}</span>
              </v-col>
            </v-row>
            <div v-if="product.Product.deletedAt">
              <v-chip small class="text-capitalize" color="red" text-color="white">Deleted At:
                {{ product.Product.deletedAt | moment("MM/DD/YYYY") }}
              </v-chip>
            </div>
          </v-card-text>
          <v-divider></v-divider>
          <v-data-table dense :loading="textLoading" :headers="headers" sort-by="date" :sort-desc="false"
            :itemsPerPage="InvoiceDetail.length" hide-default-footer :items="InvoiceDetail">
            <template v-slot:item="{ item }">
              <tr :style="{ 'background-color': color(item) }">
                <td class="text-left pointer" style="text-decoration: underline; color: blue" @click="view(item)">
                  {{ item.createdAt | moment("L") }}
                </td>

                <td>
                  <span>{{ item.message }}</span>
                </td>
                <td>
                  {{ item.type | typeFilter }}
                </td>
                <td>
                  <div v-if="item.User" class="text-capitalize">{{ item.User.name }}</div>
                  <div v-else>N/A</div>
                </td>
                <td>
                  <v-btn v-if="item.type === 'purchase'" small text icon @click="openPurchase(item)">
                    <v-icon small>edit</v-icon>
                  </v-btn>
                  <v-btn v-if="item.type === 'invoice' || item.type === 'refund'" small text icon
                    @click="openInvoice(item)">
                    <v-icon small>edit</v-icon>
                  </v-btn>
                </td>
              </tr>
            </template>
          </v-data-table>
        </div>
      </v-card-text>

      <v-divider> </v-divider>
      <v-card-actions>
        <div v-if="checkRightStatus(48)">
          <v-btn v-if="product" :disabled="!checkRightStatus(40)" :color="btnColor" @click="submit">{{ label }}</v-btn>
        </div>

        <v-spacer> </v-spacer>
        <v-btn text color="primary" @click="close">Close</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import purchaseService from "@/modules/Inventory/Purchase/service";
import Hashids from "hashids";
const hashids = new Hashids();
import { mapGetters, mapActions } from "vuex";

export default {
  data() {
    return {
      product: null,
      btnColor: "info",
      label: "Restock",
      InvoiceDetail: [],
      productStatus: null,
      textLoading: false,
      dialog: false,
      returnImeiNumber: null,
      headers: [
        {
          text: "DATE",
          align: "left",
          value: "date",
          sortable: true,
        },

        {
          text: "MESSAGE",
          align: "center",
          value: "qty",
          sortable: true,
        },
        {
          text: "TYPE",
          align: "left",
          value: "name",
          sortable: true,
        },
        {
          text: "",
          align: "",
          value: "",
          sortable: false,
        },
      ],
    };
  },
  watch: {
    openSerialSearch(val) {
      this.dialog = val;
      if (val) {
        setTimeout(() => {
          this.$refs.returnImeiNumber.focus();
          if (this.instantSearchIMEI && this.instantSearchIMEI.imeiNumber) {
            // console.log('we got imei number', this.instantSearchIMEI.imeiNumber)
            this.returnImeiNumber = this.instantSearchIMEI.imeiNumber;
            this.loadImeiNumber();
          }
        }, 100);
      }
    },
  },
  filters: {
    typeFilter(val) {
      let value = "";
      switch (val) {
        case "purchase":
          value = "Purchase";
          break;
        case "invoice":
          value = "Invoice";
          break;
        case "returnToVendor":
          value = "Return To Vendor";
          break;
        case "restock":
          value = "Restock";
          break;
        case "markItSold":
          value = "Mark It Sold";
          break;
        case "refund":
          value = "Refund";
          break;
        case "invoiceDeleted":
          value = "Serial Was Deleted";
          break;
        case "delete":
          value = "Serial Was Deleted";
          break;
        case "costUpdate":
          value = "Cost Price was updated";
          break;
        case "refundInvoice":
          value = "Invioce Refund";
          break;
        case "statusUpdated":
          value = "Status Updated";
          break;
        default:
          break;
      }
      return value;
    },
  },
  computed: {
    ...mapGetters("layout", ["getOpenSerialSearch", "getInstantSearchIMEI"]),
    ...mapGetters("global", ["currentUser", "checkRightStatus"]),
    openSerialSearch: {
      get: function () {
        return this.getOpenSerialSearch;
      },
      set: function (val) {
        this.setOpenSerialSearch(val);
      },
    },
    instantSearchIMEI: {
      get: function () {
        return this.getInstantSearchIMEI;
      },
      set: function (val) {
        this.resetInstantSearchIMEI(val);
      },
    },
  },
  methods: {
    ...mapActions("layout", ["setOpenSerialSearch", "resetInstantSearchIMEI"]),
    openPurchase(obj) {
      // console.log("obj", obj);
      this.$router.push(`/inventory/purchase/${obj.referenceNumber}/edit`);
      this.reset();
      this.close();
    },
    openInvoice(obj) {
      this.$router.push(`/invoice/${obj.referenceNumber}/edit`);
      this.reset();
      this.close();
    },
    async submit() {
      if (!this.returnImeiNumber) {
        this.$swal(`${this.label}`, `Item was ${this.label}`, "warning");
        return false;
      }
      this.$swal({
        title: "Are you sure?",
        text: `You want to ${this.label} this. serial number: ${this.returnImeiNumber}`,
        icon: "question",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes",
        cancelButtonText: "No",
      }).then(async (result) => {
        if (result.value) {
          // console.log("this.product.status", this.product.status);
          if (this.product.status === "available") {
            this.markItSold({ imeiNumber: this.returnImeiNumber });
          } else {
            this.restock({ imeiNumber: this.returnImeiNumber });
          }
        }
      });
    },
    async restock(obj) {
      await purchaseService.restock(obj).then(() => {
        this.$swal
          .mixin({
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
          })
          .fire({
            icon: "success",
            title: `Item was ${this.label}`,
          });
        this.close();
      });
    },
    async markItSold(obj) {
      await purchaseService.markItSold(obj).then(() => {
        this.$swal
          .mixin({
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
          })
          .fire({
            icon: "success",
            title: `Item was ${this.label}`,
          });
        this.close();
      });
    },
    getStatusLog() {
      return purchaseService
        .searchLog({ imeiNumber: this.returnImeiNumber })
        .then((response) => {
          console.log("response", response);
          if (response.data.serialVault) {
            this.product = response.data.serialVault;
            this.InvoiceDetail = response.data.serialVaultLog;
            if (this.product.status === "available") {
              this.label = "Mark it sold";
              this.btnColor = "orange";
            } else {
              this.label = "Restock";
              this.btnColor = "info";
            }
          } else {
            this.$swal(
              "Not Found",
              "Serial Number you enter is not found",
              "warning"
            );
          }
        })
        .catch((error) => {
          console.log("error", error);
        });
    },
    color(item) {
      let color = "white";

      if (item.type === "purchase") color = "#E1BEE7";
      if (item.type === "returnToVendor") color = "#BBDEFB";
      if (item.type === "invoice") color = "#A5D6A7";
      if (item.type === "returnToVendor") color = "#FF7043";
      if (item.type === "refundInvoice") color = "#da0000";
      if (item.type === "restock") color = "#29B6F6";
      if (item.type === "markItSold") color = "#FFEE58";
      if (item.type === "invoiceDeleted") color = "#ff0080";
      if (item.type === "statusUpdated") color = "#9FA8DA";
      return color;
    },
    reset() {
      this.InvoiceDetail = [];

      this.product = null;
      this.label = "Restock";
      this.btnColor = "info";
      this.productStatus = null;
    },
    close() {
      this.reset();
      this.returnImeiNumber = null;
      this.openSerialSearch = false;
    },
    view(item) {
      if (item.type === "invoice" || item.type === "refundInvoice") {
        this.viewInvoice(item.referenceNumber);
      }
      if (item.type === "purchase" || item.type === "returnToVendor") {
        this.viewPurchase(item.referenceNumber, this.returnImeiNumber);
      }
    },
    viewInvoice(id) {
      const hashId = hashids.encode(id);
      const routeData = this.$router.resolve({
        path: `/global/invoice/${hashId}/view`,
      });
      window.open(routeData.href, "newwindow", "width=850, height=1100");
    },
    viewPurchase(id, search) {
      const hashId = hashids.encode(id);
      const routeData = this.$router.resolve({
        path: `/global/purchase/${hashId}/packingSlip?serialNumber=${search}`,
      });
      window.open(routeData.href, "newwindow", "width=850, height=1100");
    },
    async loadImeiNumber() {
      this.reset();
      this.textLoading = true;
      await this.getStatusLog();
      // if (this.productStatus) {
      //   await this.getInvoice();
      //   await this.getPurchase();
      //   await this.getRestock();
      // }
      this.textLoading = false;
    },
  },
};
</script>

<style scoped></style>
